import $ from 'jquery';
import LoginForm from '../../forms/LoginForm';
import RegistrationForm from '../../forms/RegistrationForm';
import ExternalIdLoginForm from '../../forms/ExternalIdLoginForm';
import AutoRegistrationForm from '../../forms/AutoRegistrationForm';
import constants from "../../base/constants";
import demo from "../../../../themes/demo/demo";
import * as Sentry from "@sentry/browser";

const FALLBACK_CAMPAIGN_ID = 43;
let REMOTE_WORK_SIGN_UP_TRACKING = null;

export default function (cb) {

    app.helpers.ieFlexFix();

    // All possible url parameters
    const refParam = app.helpers.getURLParameter("ref");
    const pathParam = app.helpers.getURLParameter("uri");
    const campaignParam = app.helpers.getURLParameter("c");
    const inviteLoginCode = app.helpers.getURLParameter("code");

    if (refParam) {
        app.storage.setReferralId(refParam);
    }

    // user followed a predefined candidate link
    if (inviteLoginCode && campaignParam) {
        return app.api.getPreRegistrationDataByCode(inviteLoginCode, campaignParam)
            .then(res => {
                // here we no longer want to log the user in
                // we want him to go to the landing page and then auto fill the login form
                let af = {
                    username: res.firstName,
                    externalId: res.externalId
                };
                app.storage.setAutoFill(af);
                redirectToLandingPage(res.campaign)
            })
            .catch((err) => {
                console.error("Failed call to auto Register Candidate Using Invite Link", err);
                app.ui.error("Invalid Invitation Link");
            });
    }

    // user follow a campaign path link
    if (pathParam && pathParam.length > 1) {
        return app.api.loadCampaignByPath(pathParam.toLowerCase())
            .then(c => redirectToLandingPage(c))
            .catch(handleCampaignError);
    }

    // we have a campaign ID on the url or no parameter is set at all
    let cId = parseInt(campaignParam, 10);
    if (cId > 0) {
        let currentCampaign = app.storage.getCampaign() || {};
        if (cId !== currentCampaign.id) {
            return app.api.loadCampaignById(cId)
                .then(c => redirectToLandingPage(c))
                .catch(handleCampaignError);
        }
    }

    // there is no usable information on the URL. do we have a campaign set?
    let c = app.storage.getCampaign();
    if (typeof c === 'undefined' || c == null) {
        // Fallback to the brainsfirst campaign
        return app.api.loadCampaignById(FALLBACK_CAMPAIGN_ID)
            .then(c => redirectToLandingPage(c))
            .catch(handleCampaignError);
    }

    if (!onTheRightLandingPage(c)) {
        return redirectToLandingPage(c);
    }

    // here, campaign is loaded and we are on the proper landing page, execute DOM stuff
    initDomElements();

    initCampaign(app.storage.getCampaign());

    // proceed with initialisation
    cb();

    // ==================== functions ================================

    function initCampaign(campaign) {
        if (app.state.loggedIn === true) {
            app.events.fire.navigate('home.html');
            return;
        }

        $('.demo-image').hide()
        if (campaign.demo === true) {
            $('.render-if-not-demo').css("display", "none");
            demo.showDemo();
        } else {
            $('.render-if-demo').css("display", "none");
        }

        if (campaign.open === true) {
            $('.render-if-not-open').css("display", "none");
        } else {
            $('.render-if-open').css("display", "none");
        }

        if (campaign.registrationClose === true) {
            $('.render-if-not-registrationClose').css("display", "none");
            const form = new ExternalIdLoginForm('#autofill-tab', '#autofill-submit', campaign);
            form.init();
        } else if (campaign.open === true) {
            $('.render-if-not-registrationClose').css("display", "none");
            const form = new AutoRegistrationForm('.sign-up-tab.render-if-open', '#no-reg-submit', campaign);
            form.init();
        } else {
            const loginForm = new LoginForm('#tab-0-text-info', '.sign-in-tab', 'a.action-login', campaign);
            const registrationForm = new RegistrationForm('.sign-up-tab.render-if-not-open', $('.sign-up-tab.render-if-not-open').find('a.action'), campaign, REMOTE_WORK_SIGN_UP_TRACKING);
            registrationForm.handle(loginForm);
            registrationForm.setFormSwitcher($('.sign-up-in-box .sign-up-in-tab-buttons a'));
            registrationForm.init();
        }

        if (!campaign.active) {
            app.ui.error("Campaign currently not available");
            $('#browserErrorBtnContainer').hide();
        }

        $('.sign-up-tab.render-if-open .enter-information a.action-goto-legal').on("touch click", function () {
            $(this).closest('.carousel-legal').stop().animate({ "left": "-100%" }, constants.ui.animationSpeed);
            app.helpers.fixIESignUpInBox($('.sign-up-tab.render-if-open'));
        });
    }

    function handleCampaignError(err) {
        console.error("Can't load campaign data", err);
        app.ui.error("Campaign not found");
        $('#browserErrorBtnContainer').hide();
    }

    function initDomElements() {

        app.ui.checkCookieBar();
        $('.cookiebar .tinybutton').click(function () { app.ui.removeCookieBar(); });

        // account created pop-up, button click handler
        $('.account-created-popup a').click(function () {
            $('body').removeClass('fullscreen');
            $('.sign-up-in-box, .account-created-popup').css("display", "");
        });

        initDomElementsForRemoteWork();

        // Hack - Enable consents menu entry for HCM only (see nav.html)
        sessionStorage.setItem('consents-menu-hack', app.campaign.isHcm() && !app.campaign.isDemo());
    }

    function redirectToLandingPage(c) {
        app.storage.clear();
        app.campaign.setCampaign(c);
        let landingPageUrl = c.id === 1124 ? 'index-1124.html' : constants.landingPageFilesMap[c.landingPage];
        if (landingPageUrl) {
            app.events.fire.navigate(landingPageUrl);
            return;
        }
        app.ui.error("Invalid Link (landing page not found)");
        console.error("Landing Page not found: ", c.landingPage);
        return;
    }

    function onTheRightLandingPage(c) {
        let landingPageUrl = c.id === 1124 ? 'index-1124.html' : constants.landingPageFilesMap[c.landingPage];
        if (landingPageUrl) {
            let url = window.location.pathname || '';
            if (url.indexOf(landingPageUrl) > 0) {
                return true;
            }
        }
        return false;
    }

    function initDomElementsForRemoteWork() {
        let c = app.storage.getCampaign() || {};
        if (c.path === constants.remoteWork.path) {
            // change background for remote-work campaigns
            $('.splash-landing-1').addClass('remote-work-background');
            // send GTM event when the user logs in on a remote-work campaign
            REMOTE_WORK_SIGN_UP_TRACKING = function () {
                const name = constants.remoteWork.googleTagManagerEvents.signUp;
                try {
                    window.dataLayer.push({
                        'event': name
                    });
                } catch (e) {
                    Sentry.captureMessage(`Can't track GTM event ('${name}')`)
                }
            }
        }
    }

    function changeLanguageFlag() {
        let languageElements = [$("#lang_en"), $("#lang_nl"), $("#lang_es"), $("#lang_de"), $("#lang_zh"), $("#lang_fr"),
            $("#lang_it"), $("#lang_ja"), $("#lang_pt"), $("#lang_ru"), $("#lang_tr") ];
        languageElements.forEach((el) => {
            if (el.hasClass('active')) {
                const lang = el[0].id.split('lang_').pop();
                $("#lang-img").attr("src", `static/img/content/landing/languages/${lang.toUpperCase()}.png`)
            }
        });
    }

    changeLanguageFlag();

    $(".dropdown-menu-lang").on('click', function () {
        changeLanguageFlag();
    });

};
